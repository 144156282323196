import *as React from 'react'
import { IButtonStyles, IconButton, IContextualMenuItem, IContextualMenuProps, Stack, Text } from "@fluentui/react"
import { UserInfoExtended } from './SettingsCompany'
import { CustomDataPlainListItem } from '../interfaces/CustomDataPlainList'

export const htmlMenuProps = (insertTextCallback: (startText: string, endText?: string) => void): IContextualMenuProps | undefined => {
    return {
        items: [
            { text: 'Bold', data: ['<b>', '</b>'] },
            { text: 'Italic', data: ['<i>', '</i>'] },
            { text: 'Break', data: ['<br/>', ''] },
            { text: 'Space', data: ['&nbsp;', ''] },
            { text: 'Div', data: ['<div>', '</div>'] },
            { text: 'Span', data: ['<span>', '</span>'] },
        ].map((tag) => {
            return {
                key: tag.text,
                text: tag.text,
                data: tag.data,
                onRender: (item) => renderIconButton(item, insertTextCallback)
            } as IContextualMenuItem
        })
    }
}

export const aadFieldsMenuProps = (UserInfo: UserInfoExtended[], insertTextCallback: (startText: string, endText?: string) => void): IContextualMenuProps | undefined => {
    // console.log(settings.UserInformation)
    // const userInfoItems: UserInfoItem[] = settings.UserInformation!?.length > 0 ? settings.UserInformation!?.length > 1 ? settings.UserInformation![0].items.concat(settings.UserInformation![1].items) : settings.UserInformation![0].items : []
    if (UserInfo!?.length > 0) {
        return {
            items: UserInfo!?.map((field) => {
                return {
                    key: field.field,
                    text: field.field,
                    data: field.value,
                    onRender: (item) => renderIconButton(item, insertTextCallback)
                } as IContextualMenuItem
            })
        }
    }
    else { return { items: [] } }
}

// export const customFieldsMenuProps = (customDataItem: CustomDataItem[], insertTextCallback: (startText: string, endText?: string) => void): IContextualMenuProps | undefined => {
//     const customItems: CustomDataItem[] = [customDataItem![0], customDataItem![0]!?.SubLevels[0], customDataItem![0]!?.SubLevels[0]!?.SubLevels[0]]
//     if (customItems!?.length > 0) {
//         return {
//             items: customItems.map((dataLevel: CustomDataItem, idx) => {
//                 const lvlPrefix: string = 'cs' + ((idx === 0) ? 't' : (idx + 1).toString())
//                 if (dataLevel === undefined) return []
//                 return dataLevel!?.DataItems.map((field: CustomDataListItem) => {
//                     return {
//                         key: `${lvlPrefix}${field.FieldName}`,
//                         text: `[${lvlPrefix}${field.FieldName}]`,
//                         data: `${field.DataValue!.length > 25 ? field.DataValue!?.substring(0, 25) + '...' : field.DataValue!}`,
//                         onRender: (item) => renderIconButton(item, insertTextCallback)
//                     } as IContextualMenuItem
//                 })
//             }).flat()
//         }
//     }
//     else { return { items: [] } }
// }

export const customFieldsMenuProps = (customDataItems: CustomDataPlainListItem[], insertTextCallback: (startText: string, endText?: string) => void): IContextualMenuProps | undefined => {
    if (customDataItems!?.length > 0) {
        return {
            items: customDataItems.map((field: CustomDataPlainListItem) => {
                return {
                    key: field.FieldName,
                    text: field.FieldName,
                    data: `${field.DataValue!.length > 25 ? field.DataValue!?.substring(0, 25) + '...' : field.DataValue!}`,
                    onRender: (item) => renderIconButton(item, insertTextCallback)
                } as IContextualMenuItem
            })
        }
    }
    else { return { items: [] } }
}

export const buttonStyles: IButtonStyles = {
    root: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#fff'
    },
    rootHovered: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#e1dfdd'
    }
}

// const GetFieldValue = (field: UserInfoItem): string => {
//     try {
//         if (!!field.value) {
//             return field.value
//         }
//         else if (!!field.store && !!field.item && JSON.parse(localStorage.getItem(field.store)!)[field.item]) {
//             return JSON.parse(localStorage.getItem(field.store)!)[field.item]
//         }
//         return ''
//     } catch (error) {
//         return ''
//     }
// }

const renderIconButton = (item: IContextualMenuItem, insertTextCallback: (startText: string, endText?: string) => void): JSX.Element => {
    return (
        <IconButton
            ariaLabel={item.text!}
            styles={buttonStyles}
            style={{ width: '100%', height: 'auto', padding: 5, border: '' }}
            onClick={() => {
                insertTextCallback(item.text!)
            }}
        >
            <Stack >
                <Text variant='small' styles={{ root: { width: '100%' } }}>{item.text}</Text>
                <Text variant='small'>{item.data ? typeof item.data === 'object' ? item.data.join('') : item.data : 'n/a'}</Text>
            </Stack>
        </IconButton>
    )
}